import { useEffect } from 'react';
import { groupBy, mapValues, uniq, isEmpty, flatten } from 'lodash';

import { useAppSelector, useAppDispatch } from 'hooks';
import { apiCall, getConstraint, getDefaultParams } from 'services';
import { setTopology } from 'store/actions';

export const useTopology = () => {
  const dispatch = useAppDispatch();
  const { datasetId, processingTopoId } = useAppSelector((state) => state.session?.dataContext);
  const { topology } = useAppSelector((state) => state.sensorsData);

  useEffect(() => {
    const getTopology = async () => {
      /* Fetch reference topology */
      const findPhase = (topo: { phase1: string; phase2: string; phase3: string }): Phase => {
        if (topo.phase1 && topo.phase2 && topo.phase3) {
          return '4';
        }
        if (topo.phase1) {
          return topo.phase1?.toString() as Phase;
        } else {
          throw new Error('phase not found');
        }
      };
      if (processingTopoId.length) {
        const constraints: Constraint[] = [
          getConstraint('processing', false, undefined, processingTopoId, 'in'),
        ];
        const params: ApiBaseParams = { ...getDefaultParams(true), constraints };
        const {
          data: { data: topology },
        } = await apiCall.getTopologies(datasetId, params);

        if (topology?.length) {
          /* API needs an array of sensors ID as param to return smartMeters */
          /* for a given secondarySubstation */
          const groupedTopology = groupBy(topology, 'secSub');
          const topologyBySecSub: TopologyBySecSubstation = mapValues(groupedTopology, (t) => {
            const sensors = flatten(
              t.map((r) => [
                {
                  sensor: r.sensor,
                  feeder: r.feeder,
                  phase1: findPhase(r),
                  parentSensor: r.parentSensor,
                },
              ])
            );
            return uniq(sensors.filter((v) => !isEmpty(v)));
          });

          dispatch(setTopology(topologyBySecSub));
        }
      }
    };
    isEmpty(topology) && getTopology();
  }, [datasetId, dispatch, topology, processingTopoId]);

  return { topology };
};
