import { useCallback, useEffect, useMemo, useState } from 'react';
import { LatLngTuple } from 'leaflet';
import { findKey, isEmpty, orderBy } from 'lodash';

import { apiCall, getConstraint, getDefaultParams } from 'services';
import { sensorTypes } from 'appConstants';
import {
  filterSensorsWithLocation,
  formatSensorsLocation,
  addAnalyticsToSecondarySub,
  addExtraTopoDataToSmartMeter,
  findItemBy,
  addPvMetricsToSecondarySub,
} from 'helpers';
import { setFeeders, setSecondarySubstations, setSmartMeters } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'hooks';

export const useSensors = () => {
  const dispatch = useAppDispatch();

  const { secondarySubstations, smartMeters, feeders } = useAppSelector(
    (state) => state.sensorsData
  );
  const { networkAnalysisId, pvLoadCapacityModelIds } = useAppSelector(
    (state) => state.session.dataContext
  );
  const [currentSmartMeters, setCurrentSmartMeters] = useState<SmartMeter[]>([]);
  const [currentFeeders, setCurrentFeeders] = useState<SecondarySubstationFeeder[] | null>(null);

  const [isLoading, setIsLoading] = useState({
    secSubs: false,
    smartMeters: false,
    feeders: false,
  });

  const [mapCenter, setMapCenter] = useState<LatLngTuple>();
  const [smMapCenter, setSmMapCenter] = useState<LatLngTuple>();

  // TODO : replace by dataset location once returned by API
  useEffect(() => {
    if (secondarySubstations && secondarySubstations.length && secondarySubstations[0].location) {
      setMapCenter(secondarySubstations[0].location.coordinates);
      setSmMapCenter(secondarySubstations[0].location.coordinates);
    } else {
      setMapCenter([0, 0]);
      setSmMapCenter([0, 0]);
    }
  }, [secondarySubstations]);

  const getSecondarySubstations = useCallback(
    async (datasetId: string, signal?: AbortSignal) => {
      try {
        setIsLoading((prevState) => ({ ...prevState, secSubs: true }));
        const constraints = [
          {
            operator: 'in',
            field: 'data_type',
            values: [sensorTypes.secondarySubstation],
          },
        ];
        const params: ApiBaseParams = { ...getDefaultParams(true), constraints };
        const constraintsAnalysis: Constraint[] = [
          {
            operator: 'in',
            field: 'processing',
            values: networkAnalysisId.map((el: ProcessingSensor) => el.processing),
          },
        ];
        const constraintsPvMetrics: Constraint[] = [
          {
            operator: 'in',
            field: 'processing',
            values: pvLoadCapacityModelIds.map((el: ProcessingSensor) => el.processing),
          },
        ];
        const paramsAnalysis: ApiBaseParams = {
          ...getDefaultParams(true),
          constraints: constraintsAnalysis,
        };
        const paramsPvMetrics: ApiBaseParams = {
          ...getDefaultParams(true),
          constraints: constraintsPvMetrics,
        };
        const [sensorsResp, secSubAnalyticsResp, secSubPvMetricsResp] = await Promise.allSettled([
          apiCall.getSensors(datasetId, params, signal),
          apiCall.getSecSubstationAnalytics(datasetId, paramsAnalysis, signal),
          apiCall.getSecSubPvMetrics(datasetId, paramsPvMetrics, signal),
        ]);
        const secSubstations =
          sensorsResp.status === 'fulfilled' ? sensorsResp.value.data.data : null;
        const secSubstationAnalytics =
          secSubAnalyticsResp.status === 'fulfilled' ? secSubAnalyticsResp.value.data.data : null;
        const secSubPvMetrics =
          secSubPvMetricsResp.status === 'fulfilled' ? secSubPvMetricsResp.value.data.data : null;
        if (secSubstations?.length) {
          let formattedSecSubstations = secSubstations;
          /* Format location coordinates for every secSubstation */
          formattedSecSubstations = formatSensorsLocation(formattedSecSubstations);
          if (networkAnalysisId)
            formattedSecSubstations = addAnalyticsToSecondarySub(
              formattedSecSubstations,
              secSubstationAnalytics
            );
          if (pvLoadCapacityModelIds)
            formattedSecSubstations = addPvMetricsToSecondarySub(
              formattedSecSubstations,
              secSubPvMetrics
            );
          dispatch(setSecondarySubstations(formattedSecSubstations));
          // TODO : remove line below once dataset location returned by API
          if (formattedSecSubstations.length && formattedSecSubstations[0].location) {
            setMapCenter(formattedSecSubstations[0].location.coordinates);
            setSmMapCenter(formattedSecSubstations[0].location.coordinates);
          }
        }
      } finally {
        setIsLoading((prevState) => ({ ...prevState, secSubs: false }));
      }
    },
    [networkAnalysisId, pvLoadCapacityModelIds, dispatch]
  );

  const fetchSmartMetersAndFeedersBySecSubstation = useCallback(
    async (
      topology: TopologyBySecSubstation,
      secondarySubstationId: string,
      datasetId: string,
      withLocationOnly: boolean,
      signal?: AbortSignal
    ) => {
      try {
        const secSubstationTopology = topology[secondarySubstationId];
        /* Fetch sensors if secondarySubstation has topology rows */
        if (secSubstationTopology) {
          setIsLoading((prevState) => ({ ...prevState, smartMeters: true, feeders: true }));
          const constraints = [
            {
              operator: 'in',
              field: 'identifier',
              /* We concatenate both the feeders and smartmeters and verify the list is not empty */
              values: [...Array.from(new Set(secSubstationTopology.map((value) => value.feeder)))]
                .concat(secSubstationTopology.map((value) => value.sensor))
                .filter(Boolean),
            },
          ];

          const params: ApiBaseParams = { ...getDefaultParams(true), constraints };

          const {
            data: { data: sensors },
          } = await apiCall.getSensors(datasetId, params, signal);

          if (sensors?.length) {
            let newSmartMeters = sensors.filter(
              (s: Sensor) => s.dataType === sensorTypes.smartMeter
            );
            const newFeeders = orderBy(
              sensors.filter((s: Sensor) => s.dataType === sensorTypes.secondarySubstationFeeder),
              'name'
            );
            newSmartMeters = formatSensorsLocation(newSmartMeters);
            newSmartMeters = addExtraTopoDataToSmartMeter(
              newSmartMeters,
              secSubstationTopology,
              newFeeders
            );
            /* Store all secondarySubstation smartMeters w/o location to save API calls later */
            dispatch(
              setSmartMeters({
                ...smartMeters,
                [secondarySubstationId]: newSmartMeters,
              })
            );
            dispatch(
              setFeeders({
                ...feeders,
                [secondarySubstationId]: newFeeders.length ? newFeeders : [],
              })
            );

            if (withLocationOnly) {
              /* Filter SmartMeters with valid location only */
              newSmartMeters = filterSensorsWithLocation(newSmartMeters);
            }
            setCurrentSmartMeters(newSmartMeters);
            setCurrentFeeders(newFeeders ?? []);
          }
        }
      } finally {
        setIsLoading((prevState) => ({ ...prevState, smartMeters: false, feeders: false }));
      }
    },
    [dispatch, feeders, smartMeters]
  );

  const getSmartMetersBySecSubstation = useCallback(
    (
      topology: TopologyBySecSubstation,
      secondarySubstationId: string,
      datasetId: string,
      withLocationOnly: boolean,
      signal?: AbortSignal
    ) => {
      const secSubSmartMeters = smartMeters[secondarySubstationId];
      const secSubFeeders = feeders[secondarySubstationId];

      if (isEmpty(smartMeters) || isEmpty(secSubSmartMeters)) {
        /* Fetch smartMeters if state is empty or no smartMeters matches secondarySubstation */
        fetchSmartMetersAndFeedersBySecSubstation(
          topology,
          secondarySubstationId,
          datasetId,
          withLocationOnly,
          signal
        );
      } else if (!isEmpty(secSubSmartMeters)) {
        setCurrentSmartMeters(
          withLocationOnly
            ? (filterSensorsWithLocation(secSubSmartMeters) as SmartMeter[])
            : secSubSmartMeters
        );
        setCurrentFeeders(secSubFeeders ?? []);
      }
    },
    [feeders, fetchSmartMetersAndFeedersBySecSubstation, smartMeters]
  );

  const findSecSubBySmartMeter = (identifier: string, topology: TopologyBySecSubstation) => {
    return findKey(topology, (rows) => {
      return rows.find(
        (simplifiedTopologyRow: SimplifiedTopologyRow) =>
          identifier === simplifiedTopologyRow.sensor
      );
    });
  };

  const getSmartMeterByName = async (name: string, datasetId: string) => {
    const constraints: Constraint[] = [
      getConstraint('data_type', false, undefined, ['SmartMeter'], 'in'),
      getConstraint('name', false, undefined, [name], 'in'),
    ];

    const params: ApiBaseParams = { ...getDefaultParams(true), constraints };

    const {
      data: { data: smartMeter },
    } = await apiCall.getSensors(datasetId, params);
    return smartMeter;
  };

  const getSecSubBySmartMeterName = async (
    smartMeterName: string,
    datasetId: string,
    topology: TopologyBySecSubstation
  ) => {
    const smartMeter = await getSmartMeterByName(smartMeterName, datasetId);
    if (smartMeter?.length) {
      const value = findSecSubBySmartMeter(smartMeter[0].identifier, topology);
      if (value !== null && value !== undefined && secondarySubstations) {
        return findItemBy('identifier', secondarySubstations, value) as SecondarySubstation;
      }
    }
    return null;
  };

  const smartMetersWithLocation = useMemo(() => {
    return currentSmartMeters.filter((smartmeter: SmartMeter) => smartmeter.location);
  }, [currentSmartMeters]);

  return {
    getSecondarySubstations,
    secondarySubstations,
    getSmartMetersBySecSubstation,
    currentSmartMeters,
    smartMetersWithLocation,
    setCurrentSmartMeters,
    getSecSubBySmartMeterName,
    mapCenter,
    setMapCenter,
    smMapCenter,
    setSmMapCenter,
    isLoading,
    currentFeeders,
    setCurrentFeeders,
  };
};
