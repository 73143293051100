import React, { Fragment, useCallback, useMemo } from 'react';
import { Marker, Tooltip, Popup } from 'react-leaflet';
import { LeafletMouseEvent } from 'leaflet';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { getMarkerIcon, markdownProps } from 'appConstants/markerIcons';
import { UseCaseActions } from 'components/molecules';
import { asTemplateString, getClosestValueIndex } from 'helpers';
import { ImpactCapacity } from 'appConstants/enums';
import { useAppSelector } from 'hooks';
import { ChangeView } from '.';
import { emptyNormalizedCapacities, listImpactCapacityType } from 'appConstants';

type Props = {
  secondarySubstations: SecondarySubstation[];
  activeSecondarySubstation: SecondarySubstation | null;
  smartMeters?: SmartMeter[] | null;
  showPopup?: boolean;
  topology?: TopologyBySecSubstation | null;
  currentAnalyticsOption?: SecondarySubAnalyticsOption | null;
  selectSecondarySubstation?: ((susbtation: SecondarySubstation) => void) | null;
  selectSmartmeter?: (smartmeter: SmartMeter) => void;
  permutationByFeeder?: PermutationsByFeeder | undefined;
  impact?: {
    capacities: CapacitiesBySmartMeter;
    normalizedCapacities: NormalizedCapacitiesBySmartMeter;
    addedValues: ImpactAddedCapacitiesMap;
    selectedSmartMeter: string;
  };
  isUsecaseRebalancing?: boolean;
  hoveredSmartmeterId?: string;
  setHoveredSmartmeterId?: (smId: string) => void;
};

const SensorsCluster = ({
  permutationByFeeder,
  secondarySubstations,
  activeSecondarySubstation,
  selectSecondarySubstation = null,
  selectSmartmeter,
  currentAnalyticsOption = null,
  topology = null,
  showPopup = false,
  smartMeters = null,
  impact,
  setHoveredSmartmeterId,
  hoveredSmartmeterId,
  isUsecaseRebalancing,
}: Props) => {
  const { t } = useTranslation();
  const { useCasePermissions } = useAppSelector((state) => state.session.dataContext);

  const isActive = (substation: SecondarySubstation): boolean => {
    return activeSecondarySubstation
      ? substation.identifier === activeSecondarySubstation.identifier
      : false;
  };

  const handlePopUp = (e: LeafletMouseEvent, secSubstationId: string) => {
    /* No use case actions for a secondarySubstation with no topology */
    const hasPopUp = showPopup && topology ? !isEmpty(topology[secSubstationId]) : false;
    hasPopUp && e.target.openPopup();
  };

  const onSecSubstationMarkerClick = (e: LeafletMouseEvent, secSubstation: SecondarySubstation) => {
    e.target.closePopup();
    selectSecondarySubstation && selectSecondarySubstation(secSubstation);
  };

  const onImpactSmartmeterClick = useCallback(
    (e: LeafletMouseEvent, smartmeter: SmartMeter) => {
      selectSmartmeter && selectSmartmeter(smartmeter);
      e.target.closePopup();
    },
    [selectSmartmeter]
  );

  /* Set a class to the secondary substation marker to color it according to the analytics option
  chosen and the value of this substation analytic */
  const getMarkerClassFromValue = (secSubstation: SecondarySubstation) => {
    if (currentAnalyticsOption) {
      const value = secSubstation[currentAnalyticsOption.key];
      if (typeof value === 'number') {
        const valueIndex = getClosestValueIndex(currentAnalyticsOption.values, value);
        return valueIndex !== -1
          ? `${currentAnalyticsOption.cssClasses}-${valueIndex}`
          : 'analytics-default';
      }
    }
    return 'analytics-default';
  };

  const getMapEl = useCallback(
    (smartMeterId: string) => {
      return impact?.capacities.get(smartMeterId) as LoadCapacities;
    },
    [impact?.capacities]
  );

  const getColorOrZindexIfPermutted = useCallback(
    (smartMeter: SmartMeter, propsType: 'colorClass' | 'zIndex', isRised?: boolean | undefined) => {
      if (isRised) {
        return '100';
      }

      if (
        permutationByFeeder &&
        Object.entries(permutationByFeeder)
          .flat(2)
          .find(
            (permutation) =>
              typeof permutation !== 'string' && smartMeter.identifier === permutation.smarmeterId
          )
      ) {
        return markdownProps[propsType].permuted;
      } else {
        return markdownProps[propsType].unpermuted;
      }
    },
    [permutationByFeeder]
  );

  const smartMeterMarkers = useMemo(() => {
    return (
      smartMeters &&
      smartMeters.map((smartMeter) => {
        const getIsHovered = () => {
          return smartMeter.identifier === hoveredSmartmeterId;
        };

        const getCssClass = () => {
          let cssClass = '';

          if (isUsecaseRebalancing) {
            cssClass += getColorOrZindexIfPermutted(smartMeter, 'colorClass');
          }

          if (getIsHovered()) {
            cssClass += ' marker-hovered';
          }
          return cssClass;
        };

        return (
          <Marker
            key={`marker-${smartMeter.identifier}`}
            position={smartMeter.location.coordinates}
            icon={getMarkerIcon(
              'counter',
              undefined,
              getCssClass(),
              impact
                ? {
                    capacities:
                      impact.normalizedCapacities.get(smartMeter.identifier) ??
                      emptyNormalizedCapacities,
                    selected: smartMeter.identifier === impact.selectedSmartMeter,
                  }
                : undefined,
              getIsHovered()
            )}
            zIndexOffset={parseInt(
              getColorOrZindexIfPermutted(smartMeter, 'zIndex', getIsHovered())
            )}
            riseOnHover={true}
            eventHandlers={{
              click: (e) => {
                e.target.closeTooltip();
                impact && onImpactSmartmeterClick(e, smartMeter);
                impact && setHoveredSmartmeterId && setHoveredSmartmeterId(smartMeter.identifier);
              },
              mouseout: () => {
                if (setHoveredSmartmeterId) {
                  setHoveredSmartmeterId('');
                }
              },
              mouseover: () => {
                if (setHoveredSmartmeterId) {
                  setHoveredSmartmeterId(smartMeter.identifier);
                }
              },
            }}
          >
            {/* Only show tooltip when we are on hover */}
            {((setHoveredSmartmeterId && hoveredSmartmeterId === smartMeter.identifier) ||
              !setHoveredSmartmeterId) && (
              <Tooltip>
                <div className="tooltip-container">
                  <div className="marker-tooltip-title bold">{smartMeter.name}</div>
                  {impact && impact.capacities.get(smartMeter.identifier) && (
                    <div className="bold impact-tooltip-title">{t(`impact.tooltip.title`)}</div>
                  )}
                  {impact &&
                    impact.capacities.get(smartMeter.identifier) &&
                    listImpactCapacityType.map((el) => {
                      const smLoad = getMapEl(smartMeter.identifier);
                      let value;
                      if (el === ImpactCapacity.Added) {
                        value =
                          impact.addedValues.get(smartMeter.identifier)?.newValue ??
                          getMapEl(smartMeter.identifier)[el];
                      } else if (el === ImpactCapacity.ResPhase) {
                        value =
                          smLoad[
                            `resPhase${
                              impact.addedValues.get(smartMeter.identifier)?.phase ?? smLoad.phase
                            }`
                          ];
                      } else {
                        value = smLoad[el];
                      }
                      return (
                        <div key={el}>
                          <div className="marker-tooltip-impact-title">{`${t(
                            `impact.tooltip.${el}`
                          )}:`}</div>
                          <div className="marker-tooltip-impact-number">{value}</div>
                        </div>
                      );
                    })}
                </div>
              </Tooltip>
            )}
          </Marker>
        );
      })
    );
  }, [
    smartMeters,
    impact,
    getColorOrZindexIfPermutted,
    setHoveredSmartmeterId,
    t,
    hoveredSmartmeterId,
    isUsecaseRebalancing,
    onImpactSmartmeterClick,
    getMapEl,
  ]);

  return (
    <>
      {secondarySubstations?.map((secSubstation) => (
        <Fragment key={`marker-${secSubstation.identifier}`}>
          {secSubstation.location && (
            <Marker
              position={secSubstation?.location.coordinates}
              icon={getMarkerIcon(
                'substation',
                isActive(secSubstation),
                currentAnalyticsOption ? getMarkerClassFromValue(secSubstation) : undefined
              )}
              riseOnHover={true}
              eventHandlers={{
                click: (e) => onSecSubstationMarkerClick(e, secSubstation),
                contextmenu: (e) => handlePopUp(e, secSubstation.identifier),
              }}
            >
              <Tooltip>
                <div className={`marker-tooltip-title ${currentAnalyticsOption && 'bold'}`}>
                  {secSubstation.name}
                </div>
                {currentAnalyticsOption && secSubstation[currentAnalyticsOption.key] !== null && (
                  <>
                    <div className="marker-tooltip-analytic-title">
                      {t(asTemplateString(currentAnalyticsOption?.translationKey))}
                    </div>
                    <div>{secSubstation[currentAnalyticsOption.key]}</div>
                  </>
                )}
              </Tooltip>
              <Popup
                offset={[125, 28]}
                closeButton={false}
                className="mapping-popup"
                minWidth={200}
              >
                <UseCaseActions
                  selectedSecSubstationId={secSubstation.identifier}
                  activeLinks={{
                    analysis: useCasePermissions.includes('analysis'),
                    impact: useCasePermissions.includes('impact'),
                    rebalancing: useCasePermissions.includes('rebalancing'),
                  }}
                />
              </Popup>
            </Marker>
          )}

          {smartMeters &&
            smartMeters.length > 0 &&
            activeSecondarySubstation &&
            isActive(secSubstation) && (
              <>
                {smartMeterMarkers}
                {<ChangeView smartMeters={smartMeters} />}
              </>
            )}
        </Fragment>
      ))}
    </>
  );
};

export default SensorsCluster;
