import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  // eslint-disable-next-line
  url: process.env.REACT_APP_AUTHENTICATION_ENDPOINT ?? window.SERVER_DATA?.REACT_APP_AUTHENTICATION_ENDPOINT ?? '',
  // eslint-disable-next-line
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? window.SERVER_DATA?.REACT_APP_KEYCLOAK_REALM ?? '',
  // eslint-disable-next-line
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT ?? window.SERVER_DATA?.REACT_APP_KEYCLOAK_CLIENT ?? '',
});

export default keycloak;
